import React from "react"
import { Link, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import "../../components/layout.css"
import "../../components/layout-articles-home.css"

// import '../css/index.css'; // add some style if you want!
export default function Index({ data }) {
    const { edges: posts } = data.allMarkdownRemark
    return (
        <div className="container">
            <div className="blog-posts">
            <hr />
            <pre>jpaek.com/articles</pre>
            <hr / >
            {posts
                .filter(post => post.node.frontmatter.title.length > 0)
                .map(({ node: post }) => {
                    return (
                        <div className="blog-post-preview" key={post.id}>
                        <h5>
                        <Link to={`/articles${post.frontmatter.path}`}>{post.frontmatter.title}</Link>
                        </h5>
                        <p>{post.frontmatter.date}</p>
                        <p>{post.excerpt}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}
export const pageQuery = graphql`
  query myArticlesIndexQuery {
      allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { fields: { collection: { eq: "math" } } }) {
          edges {
              node {
                  excerpt(pruneLength: 250)
                  id
                  frontmatter {
                      title
                      date(formatString: "MMMM DD, YYYY")
                      path
                  }
              }
          }
      }
  }
`

